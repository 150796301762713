var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "view-task" },
    [
      _c("a-table", {
        attrs: {
          tid: "1",
          loading: _vm.loading,
          size: _vm.tableSize,
          columns: _vm.columns,
          "data-source": _vm.taskList,
          rowKey: "id",
          pagination: false,
        },
        scopedSlots: _vm._u([
          {
            key: "taskName",
            fn: function (text, record) {
              return _c("div", { staticClass: "task-name" }, [
                _c("span", { staticClass: "task-name-custom" }, [
                  _vm._v(" " + _vm._s(record.taskName)),
                ]),
                _c("span", { staticClass: "light-gray" }, [
                  _vm._v(_vm._s(record.remark)),
                ]),
              ])
            },
          },
          {
            key: "createBy",
            fn: function (text, record) {
              return _c("div", { staticClass: "light-gray" }, [
                _c("span", [_vm._v(" " + _vm._s(record.createBy))]),
              ])
            },
          },
          {
            key: "routeName",
            fn: function (text, record) {
              return _c("div", {}, [
                _c("a", { staticClass: "route-name", attrs: { href: "#" } }, [
                  _c(
                    "span",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.toViewRoute(record)
                        },
                      },
                    },
                    [_vm._v(_vm._s(record.routeName))]
                  ),
                ]),
              ])
            },
          },
          {
            key: "taskState",
            fn: function (text, record) {
              return _c("div", {}, [
                _vm._v(" " + _vm._s(_vm.taskStateFormat(record)) + " "),
              ])
            },
          },
          {
            key: "taskType",
            fn: function (text, record) {
              return _c("div", {}, [
                _vm._v(" " + _vm._s(_vm.taskTypeFormat(record)) + " "),
              ])
            },
          },
          {
            key: "taskSource",
            fn: function (text, record) {
              return _c("div", {}, [
                _vm._v(" " + _vm._s(_vm.taskSourceFormat(record)) + " "),
              ])
            },
          },
          {
            key: "vehicleCreate",
            fn: function (text, record) {
              return _c("div", {}, [
                _vm._v(" " + _vm._s(_vm.vehicleCreateFormat(record)) + " "),
              ])
            },
          },
          {
            key: "action",
            fn: function (text, record) {
              return _c(
                "div",
                {},
                [
                  record.taskState === "0"
                    ? [
                        _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.handleFence(record)
                              },
                            },
                          },
                          [
                            _c("a-icon", { attrs: { type: "info-circle" } }),
                            _vm._v("围栏 "),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  record.taskState === "0"
                    ? [
                        record.taskState === "0"
                          ? _c("a-divider", { attrs: { type: "vertical" } })
                          : _vm._e(),
                        _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.updateTaskTemplate(record)
                              },
                            },
                          },
                          [
                            _c("a-icon", { attrs: { type: "info-circle" } }),
                            _vm._v("修改 "),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  [
                    record.taskState === "0"
                      ? _c("a-divider", { attrs: { type: "vertical" } })
                      : _vm._e(),
                    _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.deleteTaskTemplate(record)
                          },
                        },
                      },
                      [
                        _c("a-icon", { attrs: { type: "info-circle" } }),
                        _vm._v("删除 "),
                      ],
                      1
                    ),
                  ],
                  record.vehicleCreate !== 0 && record.taskState == "0"
                    ? [
                        record.taskState === "0"
                          ? _c("a-divider", { attrs: { type: "vertical" } })
                          : _vm._e(),
                        _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.handleCreateTask(record)
                              },
                            },
                          },
                          [
                            _c("a-icon", { attrs: { type: "info-circle" } }),
                            _vm._v("同步车端任务 "),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              )
            },
          },
        ]),
      }),
      _c("a-pagination", {
        staticClass: "ant-table-pagination",
        attrs: {
          "show-size-changer": "",
          "show-quick-jumper": "",
          current: _vm.queryParam.pageNum,
          total: _vm.total,
          "page-size": _vm.queryParam.pageSize,
          showTotal: function (total) {
            return "共 " + total + " 条"
          },
        },
        on: { showSizeChange: _vm.onShowSizeChange, change: _vm.changeSize },
      }),
      _vm.routePlanVisible
        ? _c("route-plan-modal", {
            attrs: {
              vehicleId: _vm.vehicleId,
              routeId: _vm.curRouteId,
              position: { top: "4%", left: "52%" },
            },
            on: { closeRoutePlanModal: _vm.handleCloseRoutePlanModal },
          })
        : _vm._e(),
      _c(
        "vxe-modal",
        {
          attrs: {
            width: "80%",
            height: "80%",
            title: _vm.vehicleId + "-任务记录",
            "show-zoom": true,
            resize: "",
            "lock-view": false,
            mask: false,
          },
          on: { close: _vm.handleCloseTaskDetailModal },
          model: {
            value: _vm.TaskDetailVisible,
            callback: function ($$v) {
              _vm.TaskDetailVisible = $$v
            },
            expression: "TaskDetailVisible",
          },
        },
        [
          _vm.taskId
            ? _c("vehicleTaskRecord", {
                attrs: { breadcrumb: false, taskId: _vm.taskId },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.createExecTaskVisible
        ? _c("create-exec-task-modal", {
            ref: "createExecTaskModalRef",
            attrs: {
              info: _vm.info,
              position: { top: "10%", left: "45%" },
              "is-show-modal": !!_vm.createExecTaskVisible,
              "vehicle-id": _vm.vehicleId,
              taskForm: _vm.taskForm,
              taskTemplateForm: _vm.taskTemplateForm,
              withDynamic: _vm.withDynamic,
            },
            on: {
              closeTaskModal: _vm.handleCloseSendTaskModal,
              update: _vm.handleUpdate,
            },
          })
        : _vm._e(),
      _vm.createTaskTemplateVisible
        ? _c("create-task-template", {
            ref: "createTaskTemplateRef",
            attrs: {
              info: _vm.info,
              position: { top: "20%", left: "45%" },
              "is-show-modal": _vm.createTaskTemplateVisible,
              "vehicle-id": _vm.vehicleId,
              taskForm: _vm.taskForm,
              withDynamic: _vm.withDynamic,
            },
            on: {
              closeTaskModal: _vm.handleCloseCreateTaskTemplateModal,
              update: _vm.handleUpdate,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }